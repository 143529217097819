import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"

import { Caption, HeaderGroup, Link } from "../style/TextStyles.js"

function NewHeader(props) {
  const [menuOpen, toggleMenuOpen] = useState(false)

  const [boxShadow, setBoxShadow] = useState(false)
  const navRef = useRef()
  navRef.current = boxShadow
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 50
      if (navRef.current !== show) {
        setBoxShadow(show)
      }
    }
    document.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [])
  return (
        <HeaderGroup>
          <HeaderContainer boxShadow={boxShadow}>
            <HeaderContentWrapper>
              <LogoContainer>
                <Link
                  target="_parent"
                  activeClassName="active"
                  to="https://fieldproapp.com/"
                >
                  <img
                    src={require("../images/fieldpro_detect_logo.png")}
                    alt="logo"
                  />
                </Link>
              </LogoContainer>

              <MenuItemsContainer menuOpen={menuOpen}>
              </MenuItemsContainer>

              <LoginContainer menuOpen={menuOpen}>
                <CTAContainer>
                  {" "}
                  <a
                    href="https://www.fieldproapp.com/book-a-demo"
                  >
                    <MenuCTAbutton id="start_trial">
                      Book a demo
                    </MenuCTAbutton>
                  </a>
                </CTAContainer>
              </LoginContainer>
            </HeaderContentWrapper>
            <MenuIconContainer>
              <MenuIcon
                menuOpen={menuOpen}
                onClick={() => toggleMenuOpen(!menuOpen)}
              >
                <div />
                <div />
                <div />
              </MenuIcon>
            </MenuIconContainer>
          </HeaderContainer>
        </HeaderGroup>
      )
  }

export default NewHeader

export const HeaderContainer = styled.div`
  left: 0;
  margin: 0;
  padding: 16px 0;
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background-color: #fefefe;

  box-shadow: ${({ boxShadow }) =>
    boxShadow ? "0 0.5em 1em #124e5d07" : "none"};
  @media (max-width: 830px) {
  }
  @media (max-width: 512px) {
    height: auto;
  }
`
export const HeaderContentWrapper = styled.div`
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0px;
  @media (max-width: 830px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    max-width: 672px;

    margin: 24px 0px 12px 24px;
    padding: 0;
  }
  @media (max-width: 512px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 512px;
    gap: 20px;
    margin: 0;
    padding: 0px 0 8px 24px;
  }
`
export const LogoContainer = styled.div`
  img {
    height: 40px;
    margin: 0;
    padding: 0;
  }
`

export const MenuItemsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 830px) {
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

    display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};

    margin: 40px 0 0 0;
    padding: 0;
    gap: 20px;

    a {
      font-size: 1.25em;
      font-weight: 800;
    }
  }
  @media (max-width: 512px) {
    display: none;

    width: 360px;
    display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 40px 0 0 0;
    padding: 0;
    gap: 20px;

    a {
      font-size: 1.25em;
      font-weight: 800;
    }
  }
`
export const DropDownContainer = styled.div`
  position: relative;
  display: grid;
  align-items: flex-start;
  margin: 10px 0 0 0;
  padding: 0px 8px;

  @media (max-width: 830px) {
    margin: 0;
    width: 100%;
  }
  @media (max-width: 512px) {
    margin: 0;
    width: 100%;
  }
`
export const DropDownButton = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, auto);
  cursor: pointer;
  margin: 0 1.1em 0.5em 0;
  padding: 0;
  :hover {
    border-bottom: 0.2em solid;
    padding-bottom: 0.1em;
  }
  @media (max-width: 830px) {
    display: flex;
    justify-content: space-between;
    width: 100%;

    margin: 0;
  }
  :hover {
    border-bottom: none;
    padding-bottom: 0;
  }
  @media (max-width: 512px) {
    width: 100%;
    display: flex;

    margin: 0;
  }
`
export const DropDownLabel = styled.div`
  position: relative;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.5;
  color: #febd55;
  text-decoration: none;
  margin: 0;
  padding: 0.1em;
  color: #6c6c6c;

  :hover {
    font-weight: 800;
  }
  @media (max-width: 830px) {
    justify-self: flex-start;
    font-size: 1.25em;
    font-weight: 800;
    width: 762px;
  }
  @media (max-width: 512px) {
    justify-self: flex-start;
    font-size: 1.25em;
    font-weight: 800;
    width: 320px;
  }
`
///////////////////WHY Fieldpro Menu Dropdown /////////////
export const WhyMoreIcon = styled.img`
  visibility: ${({ WhyIsOpen }) => (WhyIsOpen ? "hidden" : "visible")};
  position: absolute;
  justify-self: flex-end;
  align-self: center;
  height: 0.45em;
  margin: 0.15em -1.1em 0 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    height: 0.6em;
    margin: 0.15em 0 0 0.5em;
    justify-self: flex-start;
  }
`
export const WhyLessIcon = styled.img`
  visibility: ${({ WhyIsOpen }) => (WhyIsOpen ? "visible" : "hidden")};
  position: absolute;
  justify-self: flex-end;
  align-self: center;
  height: 0.45em;
  margin: 0.15em -1.1em 0 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    height: 0.6em;
    margin: 0.15em 0 0 -1em;
    justify-self: flex-start;
  }
`
export const WhyDropDown = styled.div`
  visibility: ${({ WhyIsOpen }) => (WhyIsOpen ? "visible" : "hidden")};
  position: absolute;
  margin: 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    visibility: visible;
    display: ${({ WhyIsOpen }) => (WhyIsOpen ? "grid" : "none")};
    justify-content: flex-start;
    padding: 0 0 0 24px;
    grid-gap: 12px;
    a {
      font-size: 1.25em;
      font-weight: 600;
    }
  }
`
//////////////////features Fieldpro dropwdown menu/////////
export const FeatureMoreIcon = styled.img`
  visibility: ${({ FeatureIsOpen }) => (FeatureIsOpen ? "hidden" : "visible")};
  position: absolute;
  justify-self: flex-end;
  align-self: center;
  height: 0.45em;
  margin: 0.15em -1.1em 0 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    height: 0.6em;
    margin: 0.15em 0 0 0.5em;
    justify-self: flex-start;
  }
`
export const FeatureLessIcon = styled.img`
  visibility: ${({ FeatureIsOpen }) => (FeatureIsOpen ? "visible" : "hidden")};
  position: absolute;
  justify-self: flex-end;
  align-self: center;
  height: 0.45em;
  margin: 0.15em -1.1em 0 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    height: 0.6em;
    margin: 0.15em 0 0 -1em;
    justify-self: flex-start;
  }
`
export const FeatureDropDown = styled.div`
  visibility: ${({ FeatureIsOpen }) => (FeatureIsOpen ? "visible" : "hidden")};
  position: absolute;
  margin: 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    visibility: visible;
    display: ${({ FeatureIsOpen }) => (FeatureIsOpen ? "grid" : "none")};
    justify-content: flex-start;
    padding: 0 0 0 24px;
    grid-gap: 12px;
    a {
      font-size: 1.25em;
      font-weight: 600;
    }
  }
  @media (max-width: 450px) {
    position: relative;
    visibility: visible;
    display: ${({ FeatureIsOpen }) => (FeatureIsOpen ? "grid" : "none")};
    justify-content: flex-start;
    padding: 0 0 0 0px;
    grid-gap: 12px;
    a {
      font-size: 1.25em;
      font-weight: 600;
    }
  }
`
/////////////////resources FieldPro dropdown menu ////////
export const ResourcesMoreIcon = styled.img`
  visibility: ${({ ResourcesIsOpen }) =>
    ResourcesIsOpen ? "hidden" : "visible"};
  position: absolute;
  justify-self: flex-end;
  align-self: center;
  height: 0.45em;
  margin: 0.15em -1.1em 0 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    height: 0.6em;
    margin: 0.15em 0 0 0.5em;
    justify-self: flex-start;
  }
`
export const ResourcesLessIcon = styled.img`
  visibility: ${({ ResourcesIsOpen }) =>
    ResourcesIsOpen ? "visible" : "hidden"};
  position: absolute;
  justify-self: flex-end;
  align-self: center;
  height: 0.45em;
  margin: 0.15em -1.1em 0 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    height: 0.6em;
    margin: 0.15em 0 0 -1em;
    justify-self: flex-start;
  }
`
export const ResourcesDropDown = styled.div`
  visibility: ${({ ResourcesIsOpen }) =>
    ResourcesIsOpen ? "visible" : "hidden"};
  position: absolute;
  margin: 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    visibility: visible;
    display: ${({ ResourcesIsOpen }) => (ResourcesIsOpen ? "grid" : "none")};
    justify-content: flex-start;
    padding: 0 0 0 24px;
    grid-gap: 12px;
    a {
      font-size: 1.25em;
      font-weight: 600;
    }
  }
`
/////////////////Clients dropdown menu /////////////////////
export const ClientsMoreIcon = styled.img`
  visibility: ${({ ClientsIsOpen }) => (ClientsIsOpen ? "hidden" : "visible")};
  position: absolute;
  justify-self: flex-end;
  align-self: center;
  height: 0.45em;
  margin: 0.15em -1.1em 0 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    height: 0.6em;
    margin: 0.15em 0 0 0.5em;
    justify-self: flex-start;
  }
`
export const ClientsLessIcon = styled.img`
  visibility: ${({ ClientsIsOpen }) => (ClientsIsOpen ? "visible" : "hidden")};
  position: absolute;
  justify-self: flex-end;
  align-self: center;
  height: 0.45em;
  margin: 0.15em -1.1em 0 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    height: 0.6em;
    margin: 0.15em 0 0 -1em;
    justify-self: flex-start;
  }
`
export const ClientsDropDown = styled.div`
  visibility: ${({ ClientsIsOpen }) => (ClientsIsOpen ? "visible" : "hidden")};
  position: absolute;
  margin: 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    visibility: visible;
    display: ${({ ClientsIsOpen }) => (ClientsIsOpen ? "grid" : "none")};
    justify-content: flex-start;
    padding: 0 0 0 24px;
    grid-gap: 12px;
    a {
      font-size: 1.25em;
      font-weight: 600;
    }
  }
  @media (max-width: 512px) {
    position: relative;
    visibility: visible;
    display: ${({ ClientsIsOpen }) => (ClientsIsOpen ? "grid" : "none")};
    justify-content: flex-start;
    padding: 0 0 0 24px;
    grid-gap: 12px;
    a {
      font-size: 1.25em;
      font-weight: 600 !important;
    }
  }
`
///////////////////////////////////////////////////////

export const DropDownGroup = styled.div`
  display: grid;
  position: absolute;
  background: #fefefe;
  white-space: nowrap;
  grid-gap: 0.5em;
  border-radius: 0.25em;
  box-shadow: 0 0.4em 2em #2c2c2c10;
  width: auto;
  margin: 2.8em 0 2em -1.5em;
  padding: 1.2em 1.7em 1.3em 1.7em;
  z-index: 100;
  @media (max-width: 830px) {
    position: relative;
    justify-items: flex-start;
    background: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }
`

export const MenuItem = styled.div`
  height: 56px;
  padding: 12px 8px;
  @media (max-width: 830px) {
    height: auto;
    padding: 0px 8px;
    a {
      font-size: 1.25em;
      font-weight: 800;
    }
  }
`
export const MenuSectionTitle = styled.p`
  font-size: 0.75em !important;
  font-weight: 800;

  @media (max-width: 830px) {
    margin-top: 12px;
    padding-top: 12px;
  }
`
export const LoginContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  @media (max-width: 830px) {
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

    display: ${({ menuOpen }) => (menuOpen ? "grid" : "none")};
    margin: 0px 0 40px 0;
    padding: 0;
    gap: 20px;
    a {
      font-size: 1.25em;
      font-weight: 800;
    }
  }
  @media (max-width: 512px) {
    display: none;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

    width: 360px;
    display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};
    margin: 0px 0 0 0;
    padding: 0;
    gap: 20px;
    a {
      font-size: 1.25em;
      font-weight: 800;
    }
  }
`
export const SeparatorContainer = styled.div`
  margin-left: 12px;
  margin-right: 12px;
  @media (max-width: 830px) {
    display: none;
  }
`

export const Separator = styled.div`
  width: 2px;
  height: 32px;
  background-color: #eaeef2;
  margin: 0;
  padding: 0;
  @media (max-width: 830px) {
    display: none;
  }
`

export const CTAContainer = styled.div`
  margin: -6px 0 0 8px;
  padding: 0;

  @media (max-width: 830px) {
    margin: 20px 0 0 0;
  }
  @media (max-width: 512px) {
    margin: 0;
  }
`

export const MenuCTAbutton = styled.div`
  margin: 0;
  padding: 0.5em;

  background: #febd55;
  color: #fefefe;
  border-radius: 0.2em;
  border: none;
  font-weight: 600;
  font-size: 1em;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  :hover {
    box-shadow: 0 1em 2em #2c2c2c16;
  }
  @media (max-width: 830px) {
    margin: 20px 0 0 0;

    padding: 12px 16px;
    font-weight: 600;
    font-size: 1.25em;
    text-align: center;
  }
  @media (max-width: 512px) {
    margin: 0 0 40px 0;
  }
`

///////////WHY FIELDPRO DROPDOWN MENU ESSENTIALS////

export const WhyFieldProMenuGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 48px;
  @media (max-width: 830px) {
    grid-template-columns: repeat(1, auto);
    grid-gap: 20px;
  }
`
export const ForyourIndustryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const ForyourWorkflowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: 830px) {
    position: relative;
    justify-items: flex-start;
  }
`

export const HorizontalSeparator = styled.div`
  width: auto;
  height: 2.4px;
  background-color: #e6e6e630;
  margin: 0;
  padding: 0;
  @media (max-width: 830px) {
    width: 720px;
    margin: 0;
  }
  @media (max-width: 512px) {
    width: 165px;
    margin: 0 0 16px 0;
  }
`
export const MenuRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 20px 48px;
  @media (max-width: 830px) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 0 0 0 12px;
    grid-gap: 8px;
  }
  @media (max-width: 512px) {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    margin: 0 0 0 12px;
    grid-gap: 8px;
  }
`
///////////

export const MenuCard = styled.div`
  display: flex;
  width: 280px;
  gap: 8px;
  padding: 8px 8px 8px 8px;
  white-space: normal;
  :hover {
    background-color: #febd5510;
    transition: 0.4s;
  }
  p {
    font-weight: 600;
    font-size: 0.75em !important;
    color: #6c6c6c;
  }
  @media (max-width: 830px) {
    width: 600px;
  }
  @media (max-width: 450px) {
    width: 320px;
    padding: 8px 8px 8px 0px;
  }
`
export const IconPicture = styled.div`
  padding: 0;
  margin: 0;
  border: none;

  img {
    width: 60px;
    line-height: 0;

    display: block;
  }
  @media (max-width: 450px) {
    img {
      width: 32px;
    }
  }
`
export const MenuText = styled.div`
  display: grid;
`
export const TitleText = styled.div`
  font-weight: 600;
  font-size: 0.875em;
  line-height: 1.5;
  color: #2c2c2c;
`
export const DescText = styled(Caption)``
//////////////////////LANGUAGE DROPDOWN //////////////////////////////
export const MenuLangBtns = styled.div`
  margin: 0 0em 0 1em;
  padding: 0;

  :hover {
    a {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  :active {
    a {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  @media (max-width: 830px) {
    display: none;
  }
`
export const MenuLangBtnsMobile = styled.div`
  display: none;
  @media (max-width: 830px) {
    display: grid;
  }
`
export const DropDownLangContainer = styled.div`
  position: relative;
  display: grid;
  align-items: flex-start;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;

  @media (max-width: 830px) {
    margin: 0;
  }
`
export const LanguageOption = styled.div`
  display: flex;

  padding: 0;
  margin: 0;
`
export const DropDownLangButton = styled.div`
  cursor: pointer;

  padding: 0;
  img {
    margin: 0;
    padding: 0;
  }
  :hover {
    border-bottom: none;
    padding-bottom: 0.1em;
  }
  @media (max-width: 830px) {
    display: flex;
    justify-self: flex-end;
    flex-wrap: nowrap;
    margin: 0;
  }
  :hover {
    border-bottom: none;
    padding-bottom: 0;
  }
`
export const DropDownLangLabel = styled.div`
  position: relative;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.6;
  color: #6c6c6c;
  margin: 0em 000;
  padding: 0;
  :hover {
    font-weight: 800;
  }
  @media (max-width: 830px) {
    justify-self: flex-start;
    font-size: 1.5em;
    font-weight: 800;
  }
`
export const DropDownLangGroup = styled.div`
  display: grid;
  position: absolute;
  background: #fefefe;
  white-space: nowrap;
  grid-gap: 0.025em;
  border-radius: 0.25em;
  box-shadow: 0 0.5em 1em #124e5d07;

  width: auto;
  margin: 2.3em 0 1em -2em;
  padding: 1.2em 3em 1.3em 1.7em;
  z-index: 100;
  @media (max-width: 830px) {
    position: relative;
    justify-items: flex-start;
    background: none;
    box-shadow: none;
    margin: 0.6em 0 0 1em;
    padding: 0;
  }
`
export const LangDropDown = styled.div`
  position: absolute;
  margin: 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    visibility: visible;

    justify-content: flex-start;
  }
`
///////menu mobile////////////

export const MenuIconContainer = styled.div`
  display: none;
  @media (max-width: 830px) {
    display: block;
    position: absolute;
    top: 16px;
    right: 1.8em;
  }
`
export const MenuIcon = styled.div`
  cursor: pointer;
  background: transparent;
  border: none;
  display: grid;
  height: 2em;
  outline: thin-dotted;
  z-index: 200;
  div {
    width: 2em;
    height: 0.25em;
    background: #2c2c2c;
    border-radius: 0.5em;
    transform-origin: 0.05em;
    :first-child {
      transform: ${({ menuOpen }) =>
        menuOpen ? "rotate(45deg)" : "rotate(0)"};
    }
    :nth-child(2) {
      opacity: ${({ menuOpen }) => (menuOpen ? "0" : "1")};
    }
    :nth-child(3) {
      transform: ${({ menuOpen }) =>
        menuOpen ? "rotate(-45deg)" : "rotate(0)"};
    }
  }
`
