import styled from "styled-components"

export const Link = styled.a`
  font-family: 'Basier Circle';
  color: #2c2c2c;
  font-weight: 800;
  font-size: 1.25em;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const Heading = styled.h1`
  font-family: 'Basier Circle';
  font-weight: 800;

  line-height: 1.25;
  margin: 0 0 0 0;
  font-size: clamp(1.5rem, 4vw + 1rem, 4rem);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const H1 = styled.h1`
  font-family: 'Basier Circle';
  font-weight: 700;

  line-height: 1.25;
  margin: 0 0 0 0;
  font-size: clamp(1.5rem, 80px, 3rem);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const H2 = styled.h2`
  font-family: 'Basier Circle';
  font-weight: 800;
  line-height: 1.25;
  margin: 1em 0 1em 0;
  font-size: clamp(1.5rem, 4vw + 1rem, 2rem);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const H3 = styled.h3`
  font-family: 'Basier Circle';
  font-weight: 800;
  font-size: 1.5em;
  line-height: 1.25;
  margin: 1em 0 1em 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const H4 = styled.h4`
  font-family: 'Basier Circle';
  font-weight: 800;
  font-size: 1.25em;
  line-height: 1.25;
  margin: 1em 0 1em 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const H5 = styled.h5`
  font-family: 'Basier Circle';
  font-weight: 800;
  font-size: 1em;
  line-height: 1.25;
  margin: 1em 0 1em 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const BodyMain = styled.p`
  font-family: 'Basier Circle';
  font-weight: 400;
  font-size: 1.25em;
  line-height: 1.5;
  margin: 0 0 1em 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const SubTitle = styled.p`
  font-family: 'Basier Circle';
  font-weight: 400;
  font-size: 1.3em;
  line-height: 2;
  margin: 0 0 1em 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const P = styled.p`
  font-family: 'Basier Circle';
  font-weight: 400;
  font-size: 1em;
  line-height: 1.5;
  margin: 0 0 1em 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const BlogText = styled.p`
  font-weight: 400;
  font-size: 1.25em;
  line-height: 1.5;
  margin: 0 0 1em 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const QuoteText = styled.p`
  font-size: 1.5em;
  font-weight: 600;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.25;
  letter-spacing: -0.48px;
  color: #2c2c2c;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
export const Button = styled.button`
  margin: 1.9em 0 0 1.25em;
  padding: auto;
  width: 10em;
  height: 3em;
  background: #febd55;
  color: #2c2c2c;
  border-radius: 0.2em;
  border: none;
  font-weight: 800;
  font-size: 1.25em;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  :hover {
    box-shadow: 0 1em 2em #2c2c2c16;
  }
`

export const Input = styled.input`
  margin: 0;
  padding: 0 1em;
  height: 3em;
  width: 18em;
  border-style: solid;
  border-color: #2c2c2c;
  border-width: 0.1em;
  border-radius: 0.2em;
  font-size: 1.2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const Label = styled.label`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #6c6c6c;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

export const HeaderGroup = styled.div`
  p {
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a {
    font-weight: 600;
    font-size: 1em;
    line-height: 1.5;
    color: #febd55;
    text-decoration: none;
    margin: 0;
    padding: 0.1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    position: relative;
    ::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2.4px;
      bottom: 0;
      left: 0;
      background-color: #febd5590;
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    :hover ::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
    :active {
      font-weight: 800;
      border-bottom: 0.2em solid;
      padding-bottom: 0.1em;
    }
  }
  .active {
    font-weight: 800;
    padding-bottom: 0.1em;
  }
  button {
    color: #febd55;
    border-color: #febd5590;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px;
    background: none;
    cursor: pointer;
    padding: 8px;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    :hover {
      opacity: 1 !important;
      box-shadow: 0 1em 1em #2c2c2c07;
    }
  }
  .darkGrey {
    color: #6c6c6c;
    :hover {
      border-color: #6c6c6c;
    }

    :active {
      color: #2c2c2c;
      border-color: #2c2c2c;
    }
  }

  .onGreenBackground {
    color: #fefefe;
    :hover {
      border-color: #fefefe;
    }
    :active {
      color: #2c2c2c;
      border-color: #2c2c2c;
    }
  }
`

export const Caption = styled.p`
  font-weight: 600;
  font-size: 0.75em;
  line-height: 1.5;
  color: #6c6c6c;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`
