import styled from "@emotion/styled";

export const RootWrapperLandingPage = styled.div`
	min-height: 100vh;
	background-color: white;
	position: relative;
`;

export const Section = styled.section`
	position: relative;
	width: auto;
	margin: 0px auto;
	padding: 5rem 0;
`;

export const TwoHalvesContent = styled.div`
	display: grid;
	max-width: 1280px;
	margin: 0 auto;
	grid-template-columns: 50% 50%;
	@media (max-width: 830px) {
		width: auto;
    max-width: 780px;
    padding: 0 40px;
		grid-template-columns: repeat(1, auto);
		grid-gap: 80px;
	}
  @media (max-width: 450px) {
    width: auto;
    max-width: 380px;
    padding: 0 40px;
    grid-template-columns: repeat(1, auto);
    margin: 40px auto;
    grid-gap: 20px;
  }
`;

export const MainTitleContent = styled.div`
  z-index: 1;
  position: relative;
  padding: 0 20px;
	@media (max-width: 830px) {
    padding: 0;
	}
`

export const UploadCta = styled.div`
  width: fit-content;
  border-radius: 8px;
  box-shadow: 0px 7px 6px 0px grey;
  padding: 100px 100px;
  position: relative;
	@media (max-width: 830px) {
    padding: 80px;
	}
  @media (max-width: 450px) {
    padding: 60px;
  }
`

export const GiveItATryImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(100%, -30%);
	@media (max-width: 450px) {
    display: none;
	}
`

export const ExamplesContainer = styled.div`
  display: flex;
  margin-top: 80px;
	@media (max-width: 450px) {
    display: block;
  }
`

export const ExamplesImgsContainer = styled.div`
  display: flex;
  margin-left: 40px;
	@media (max-width: 450px) {
    margin-top: 40px;
    margin-left: 0;
  }
`

export const PredictionContent = styled.div`
	display: grid;
	max-width: 1280px;
	margin: 0 auto;
	grid-template-columns: 45% 10% 45%;
	@media (max-width: 830px) {
		width: auto;
    max-width: 780px;
    padding: 0 80px;
		grid-template-columns: repeat(1, auto);
		grid-gap: 40px;
	}
  @media (max-width: 450px) {
    width: auto;
    max-width: 380px;
    grid-template-columns: repeat(1, auto);
    margin: 40px auto;
    grid-gap: 20px;
  }
`;

export const ResultsArrowContainer = styled.div`
  margin: auto;
`

export const ResultsArrowImg = styled.img`
  @media (max-width: 830px) {
    transform: rotate(90deg);
  }
`

export const MainTitleBgImg = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`

export const Content = styled.div`
	max-width: 1280px;
	margin: 0 auto;
	@media (max-width: 830px) {
		width: auto;
    max-width: 780px;
		grid-template-columns: repeat(1, auto);
		grid-gap: 40px;
	}
  @media (max-width: 450px) {
    width: auto;
    max-width: 380px;
    grid-template-columns: repeat(1, auto);
    margin: 40px auto;
    grid-gap: 20px;
  }
`;

export const FeatureCard = styled.div`
	margin-bottom: 80px;
	border-radius: 16px;
	display: grid;
	padding: 40px 40px;
`;

export const FeatureCardODContainer = styled.div`
	margin-bottom: 80px;
	border-radius: 16px;
	display: grid;
	padding: 40px 40px;
  grid-template-columns: 30% 70%;
  grid-column-gap: 1em;
  background-color: #FE938B;
	@media (max-width: 830px) {
    display: block;
  }
`;

export const FeatureCardODTextContent = styled.div`
  @media (max-width: 830px) {
    margin-bottom: 24px;
  }

  p{
    @media (max-width: 830px) {
      margin: auto;
      padding: 0 40px 0 0;
    }
  }
`;

export const FeatureCardKPIContainer = styled.div`
	margin-bottom: 80px;
	border-radius: 16px;
	display: grid;
	padding: 40px 40px;
  background-color: #639FAA;
`;

export const FeatureCardKPIsCardsContainer = styled.div`\
  display: grid;
  grid-template-columns: repeat(4, auto);
  @media (max-width: 830px) {
    grid-template-columns: repeat(2, 40%);
    grid-column-gap: 20%;
  }
  @media (max-width: 450px) {
    grid-template-columns: repeat(2, 45%);
    grid-column-gap: 10%;
  }
`;

export const FeatureCardPlanogramContainer = styled.div`
	margin-bottom: 80px;
	border-radius: 16px;
	display: grid;
	padding: 40px 40px;
  grid-template-columns: 50% 50%;
  grid-column-gap: 1em;
  background-color: rgba(99, 159, 170, 0.2);
  @media (max-width: 830px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const FeatureCardPlanogramsCardsContainer = styled.div`\
  display: grid;
  grid-template-columns: repeat(4, auto);
  @media (max-width: 830px) {
    grid-template-columns: repeat(2, auto);
  }
`;

export const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 80px;
  margin: 160px 0 160px 0;
  @media (max-width: 830px) {
    width: auto;
    grid-template-columns: repeat(1, auto);
    grid-gap: 40px;
  }
  @media (max-width: 450px) {
    width: auto;
    grid-template-columns: repeat(1, auto);
    margin: 40px 0 40px 0;
    grid-gap: 20px;
  }
`
export const OfficePicture = styled.div`
  padding: 140px 000;
  @media (max-width: 830px) {
    padding: 0;
  }
  @media (max-width: 450px) {
    padding: 60px 000;
  }
`
export const ContactFormSection = styled.div`
  @media (max-width: 830px) {
    margin: 0 0px 0 40px;
  }
  @media (max-width: 450px) {
    margin: 0 0px 0 20px;
  }
`