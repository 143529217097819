import React from "react"
import styled from "styled-components"

import { H2 } from "../style/TextStyles"
import { CTAContainer, MenuCTAbutton } from "./HeaderNew"

function Cta(props) {
  return (
    <CtaBackground>
      <CtaIllustartion>
        <img
          src={require('../images/cta_bg.png')}
          style={{ width: "100vw" }}
          alt=""
        />
      </CtaIllustartion>
      <CtaWrapper>
        <CtaContent>
          <CtaTagline>
            Ready to optimize your field sales?
          </CtaTagline>
          <CtaButtons>
            <CTAContainer>
              {" "}
              <a
                href="https://www.fieldproapp.com/book-a-demo"
              >
                <MenuCTAbutton id="start_trial">
                  Book a demo
                </MenuCTAbutton>
              </a>
            </CTAContainer>
          </CtaButtons>
        </CtaContent>
      </CtaWrapper>
    </CtaBackground>
  )
}
export default Cta
const CtaBackground = styled.div`
  background-color: #124e5d;
  width: 100vw;
  position: relative;
  left: -8px;
`
const CtaWrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 200px 0 200px 0;
  @media (max-width: 830px) {
    padding: 100px 48px 100px 48px;
  }
  @media (max-width: 512px) {
    padding: 80px 24px 80px 24px;
  }
`
const CtaIllustartion = styled.div`
  position: absolute;

  align-self: center;
  top: 100px;
`
const CtaContent = styled.div`
  position: relative;
  z-index: 1;
  max-width: 1280px;
  padding: 0 0 0 0;
  @media (max-width: 830px) {
    padding: 0;
  }
  @media (max-width: 512px) {
    padding: 0px;
  }
`
const CtaTagline = styled(H2)`
  font-size: 3.25em;
  color: #fefefe;
  max-width: 600px;
  margin: 0 0 20px 0;
  @media (max-width: 830px) {
    padding: 0px;
  }
  @media (max-width: 512px) {
    padding: 0px;
    max-width: 320px;
    font-size: 2.5em;
    margin: 0 0 40px 0;
  }
`
const CtaButtons = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  @media (max-width: 512px) {
    flex-direction: column;
  }
`
