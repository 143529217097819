import React from "react"
import styled from "styled-components"

import Layout from "../Layout"
import { H3, Link, P } from "../style/TextStyles.js"

function Footer(props) {
  return (
    <Layout>
      <FooterContainer>
        <FooterGroup>
          <FooterLogo>
            <Link target="_blank" href="https://fieldproapp.com/">
              <img
                src={require("../images/optimetriks-logo.png")}
                alt=""
              />
            </Link>
          </FooterLogo>
          <LinkGroup>
            <H3>Solutions</H3>
            <Link target="_blank" href="https://www.fieldproapp.com/retail-execution">In-Store Execution</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/sales">Sales Automation</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/field-team-management">Field Team Management</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/mobilecrm">Mobile CRM</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/mobile-inspection">Mobile Inspection</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/workflowbuilder">Workflow Builder</Link>
          </LinkGroup>
          <LinkGroup>
            <H3>Industries</H3>

            <Link target="_blank" href="https://www.fieldproapp.com/consumer-goods">Consumer Goods</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/financial-services"> Financial Services</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/energy-access">Energy Access</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/agriculture"> Agriculture</Link>
          </LinkGroup>
          <LinkGroup>
            <H3>Resources</H3>
            <Link target="_blank" href="https://www.fieldproapp.com/testimonials">Testimonials</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/blog">Case Studies</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/blog">Blog</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/partners">Become a Partner</Link>
            {/* <Link target="_blank" href="https://www.fieldproapp.com/find-a-partner">Find a Partner</Link> */}
            <Link target="_blank" href="https://www.fieldproapp.com/workflowtemplates">Workflow Templates</Link>
            <Link
              target="_blank" href="https://optimetriks.invisionapp.com/dsm/design/optimetriks-brand"
            >
              Brand Assets
            </Link>
          </LinkGroup>
          <LinkGroup>
            <H3>Company</H3>
            <Link target="_blank" href="https://www.fieldproapp.com/about">About Us</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/contact">Contact Us</Link>
            <Link target="_blank" href="https://optimetriks.factorialhr.com/">Careers</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/privacypolicy">Privacy Policy</Link>
            <Link target="_blank" href="https://www.fieldproapp.com/terms">Terms and Conditions</Link>
          </LinkGroup>
          <LinkGroup>
            <H3>For Clients</H3>
            <Link target="_blank" href="https://web.fieldproapp.com/">Login</Link>
            <Link target="_blank" href="https://help.fieldproapp.com/">Help Center</Link>
          </LinkGroup>
        </FooterGroup>
        <BottomFooterGroup>
          <Copyright>
            <P>
              © {new Date().getFullYear()} | Optimetriks | All rights reserved
            </P>
          </Copyright>

          <SocialIconsGroup>
            {" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.optimetriks.smala&hl=en&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={require("../images/google-play.svg").default} alt="Playstore Logo" />
            </a>
            <a
              href="https://www.linkedin.com/company/optimetriks"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={require("../images/linkedin.svg").default} alt="LinkedIn Logo" />
            </a>
            <a
              href="https://www.facebook.com/fieldproapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={require("../images/facebook.svg").default} alt="Facebook Logo" />
            </a>
            <a
              href="https://www.youtube.com/channel/UC3lznqy3g-OCcUvci_FkVzg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={require("../images/youtube.svg").default} alt="Youtube Logo" />
            </a>
            <a
              href="https://twitter.com/optimetriks"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon src={require("../images/twitter.svg").default} alt="Twitter Logo" />
            </a>
            <a
              href="https://wa.me/254796142344"
              target="_blank"
              rel="noopener noreferrer"
            >
              <SocialIcon
                src={require("../images/whatsapp.svg").default}
                alt="FieldPro Whatsapp Contact"
              />
            </a>
          </SocialIconsGroup>
        </BottomFooterGroup>
      </FooterContainer>
    </Layout>
  )
}

export default Footer

export const FooterContainer = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100vw;
  margin: 0;
  padding: 3em 0 2em 0;
  background-color: #f5f5f5;
  @media (max-width: 830px) {
    overflow-x: hidden;
    width: auto;
  }
`
export const FooterGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);

  grid-gap: 2em;
  max-width: 80em;
  margin: 0 0 0 6em;
  padding: 0;
  @media (max-width: 830px) {
    grid-template-columns: repeat(1, auto);
    width: 672px;
    margin: 0;
  }
  @media (max-width: 512px) {
    grid-template-columns: repeat(1, auto);
    width: 20em;
    margin: 0 0 40px -324px;
    padding: 0;
  }
`
export const FooterLogo = styled.div`
  width: 10em;
  margin: 0.2em 4em 0 0;
  padding: 0;
  @media (max-width: 830px) {
    margin: 0.2em 10em -2em -0.2em;
  }
`
export const LinkGroup = styled.div`
  display: grid;
  align-content: flex-start;
  grid-gap: 0.5em;
  min-width: 10em;

  margin: 0;
  padding: 0;
  h3 {
    font-size: 1em;
    font-weight: 900;
    color: #2c2c2c;
    margin: 0;
    padding: 0;
  }
  a {
    font-size: 0.9em;
    font-weight: 500;
    color: #2c2c2c;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 830px) {
    width: 0;
    margin: 0 -3em -1em 0;
  }
`
export const Copyright = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  margin: 2em 0 0 0;
  padding: 0;
  p {
    font-size: 0.9em;
    font-weight: 500;
    color: #2c2c2c;
    margin: 0;
    padding: 0;
  }
  @media (max-width: 830px) {
    margin: 0;
  }
  @media (max-width: 512px) {
    margin: 0;
  }
`
export const BottomFooterGroup = styled.div`
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 320px;
  margin: 2em 0 0 240px;
  @media (max-width: 830px) {
    grid-template-columns: repeat(2, auto);
    grid-gap: 0px;
    width: 672px;
    margin: 60px 0 0 0;
  }
  @media (max-width: 512px) {
    grid-template-columns: repeat(1, auto);
    width: 672px;
    margin: 0;
    grid-gap: 20px;
  }
`

export const SocialIconsGroup = styled.div`
  margin: 40px 0 0 0;
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-gap: 20px;
  justify-items: center;
  align-items: center;
  @media (max-width: 830px) {
    grid-template-columns: repeat(6, auto);

    margin: 0;
  }
  @media (max-width: 512px) {
    grid-template-columns: repeat(6, auto);

    margin: 0 400px 0 0;
    padding: 0;
    grid-gap: -100px;
  }
`
export const SocialIcon = styled.img``

///Language switcher
export const LangDropDown = styled.div`
  position: absolute;
  margin: 0;
  padding: 0;
  @media (max-width: 830px) {
    position: relative;
    visibility: visible;

    justify-content: flex-start;
  }
`
export const DropDownLangGroup = styled.div`
  display: flex;
  flex-direction: column-reverse;
  bottom: -20px;
  position: absolute;
  background: #fefefe;
  white-space: nowrap;
  grid-gap: 0.025em;
  border-radius: 0.25em;
  box-shadow: 0 0.4em 2em #2c2c2c10;
  width: auto;
  margin: 2.3em 0 1em -2em;
  padding: 1.2em 3em 1.3em 1.7em;
  z-index: 100;
  @media (max-width: 830px) {
    position: relative;
    justify-items: flex-start;
    background: none;
    box-shadow: none;
    margin: 0.6em 0 0 1em;
    padding: 0;
  }
`
export const DropDownLangLabel = styled.div`
  position: relative;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.6;
  color: #6c6c6c;
  margin: 0em 000;
  padding: 0;
  :hover {
    font-weight: 800;
  }
  @media (max-width: 830px) {
    justify-self: flex-start;
    font-size: 1.5em;
    font-weight: 800;
  }
`
export const DropDownLangButton = styled.div`
  position: relative;
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(3, auto);
  cursor: pointer;
  margin: 0 1.1em 0em 0;
  padding: 0;
  :hover {
    border-bottom: none;
    padding-bottom: 0.1em;
  }
  @media (max-width: 830px) {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 1.1em 0 0.1em;
  }
  :hover {
    border-bottom: none;
    padding-bottom: 0;
  }
`
export const LanguageOption = styled.div`
  display: flex;

  padding: 0;
  margin: 0;
`
export const DropDownLangContainer = styled.div`
  position: relative;
  display: grid;
  align-items: flex-start;
  margin: 0px 0 0 0;
  padding: 0 0 0 0;

  @media (max-width: 830px) {
    margin: 0;
  }
`
export const MenuLangBtns = styled.div`
  margin: 0 0em 0 1em;
  padding: 0;

  :hover {
    a {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  :active {
    a {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
  @media (max-width: 830px) {
    display: none;
  }
`
export const MenuLangBtnsMobile = styled.div`
  display: none;
  @media (max-width: 830px) {
    display: grid;
  }
`
