import { CircularProgress } from "@material-ui/core";
import * as ort from "onnxruntime-web";
import React, { useEffect, useRef, useState } from "react";
import "./style/App.css";
import labels from "./utils/labels.json";
import { renderBoxes } from "./utils/renderBox";

import Cta from "./components/Cta";
import Footer from "./components/Footer";
import NewHeader, { CTAContainer, MenuCTAbutton } from "./components/HeaderNew";
import { Content, ExamplesContainer, ExamplesImgsContainer, FeatureCardKPIContainer, FeatureCardKPIsCardsContainer, FeatureCardODContainer, FeatureCardODTextContent, FeatureCardPlanogramContainer, GiveItATryImg, MainTitleBgImg, MainTitleContent, PredictionContent, ResultsArrowContainer, ResultsArrowImg, Section, TwoHalvesContent, UploadCta } from "./style/LandingStyles.js";
import { H1, H2, P, SubTitle } from "./style/TextStyles.js";

const App = () => {
  const [session, setSession] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [img, setImg] = useState(false);
  const inputImage = useRef(null);
  const inputImageRef = useRef(null);
  const outputImageRef = useRef(null);
  const canvasRef = useRef(null);
  const drop = useRef(null);
  const contactFormRef = useRef(null);

  // // configs
  const modelName = "sku110k-tiny";
  // const modelName = 'yolov7-tiny-640-best';
  const IMG_SIZE = 640

  const {min, max} = Math;
  const nms = (foundLocations, overlapThresh) => {
    if (foundLocations.length === 0) {
      return [];
    }

    const pick = [];
    var suppress = []
    
    foundLocations = foundLocations.map(box => { // TODO: replace with vectorization
      return {
        x1: box.bounding[0],
        y1: box.bounding[1],
        x2: box.bounding[0] + box.bounding[2],
        y2: box.bounding[1] + box.bounding[3],
        width: box.bounding[2],
        height: box.bounding[3],
        area: (box.bounding[3] + 1) * (box.bounding[2] + 1)
      }
    });

    foundLocations.sort((b1, b2) => {
      return b1.y2 - b2.y2;
    });

    while (foundLocations.length > 0) {
      let last = foundLocations[foundLocations.length - 1];
      pick.push(last);
      suppress = [last];

      for (let i = 0; i < foundLocations.length - 1; i ++) {
        const box = foundLocations[i];
        const xx1 = max(box.x1, last.x1)
        const yy1 = max(box.y1, last.y1)
        const xx2 = min(box.x2, last.x2);
        const yy2 = min(box.y2, last.y2)
        const w = max(0, xx2 - xx1 + 1);
        const h = max(0, yy2 - yy1 + 1);
        const overlap = (w * h ) / box.area;
        if (overlap > overlapThresh) {
          suppress.push(foundLocations[i])
        }
      }
      
      foundLocations = foundLocations.filter((box) => {
        return !suppress.find((supp) => {
          return supp === box;
        })
      });
    }
    return pick;
  };

  /**
   * Callback function to detect image when loaded
   */
  const detectImage = async (ctx) => {
    const mat = cv.imread(inputImageRef.current); // read from img tag
    const matC3 = new cv.Mat(IMG_SIZE, IMG_SIZE, cv.CV_8UC3); // new image matrix (IMG_SIZE x IMG_SIZE)
    cv.cvtColor(mat, matC3, cv.COLOR_RGBA2BGR); // RGBA to BGR
    const input = cv.blobFromImage(
      matC3,
      1 / 255.0,
      new cv.Size(IMG_SIZE, IMG_SIZE),
      new cv.Scalar(0, 0, 0),
      true,
      false
    ); // preprocessing image matrix
    // release
    mat.delete();
    matC3.delete();

    const tensor = new ort.Tensor("float32", input.data32F, [1, 3, IMG_SIZE, IMG_SIZE]); // to ort.Tensor

    let output = null;

    if(!session){
      let session = null;
      ort.InferenceSession.create(`${process.env.PUBLIC_URL}/model/${modelName}.onnx`).then(
        async (yolov7) => {
          setSession(yolov7);
          session = yolov7;
          const { output: o } = await session.run({ images: tensor });
          output = o;
        }
      )
    }else{
      const { output: o } = await session.run({ images: tensor });
      output = o;
    }

    var boxes = [];

    var maxs = [0, 0, 0, 0, 0, 0]
    var mins = [0, 0, 0, 0, 0, 0]
    
    // looping through output
    for (let r = 0; r < output.size; r += output.dims[2]) {
      const data = output.data.slice(r, r + output.dims[2]); // get rows
      const x0 = data[0] 
      const y0 = data[1] 
      const w = data[2] 
      const h = data[3] 
      const classId = data[4]
      const score = data[5];

      for(let i = 0; i < 6; i++){
        if(maxs[i] < data[i]){
          maxs[i] = data[i]
        }
        if(mins[i] > data[i]){
          mins[i] = data[i]
        }
      }
      if(classId > 0.3){
        let realW = w
        let realH = h
        let realX0 = x0 - realW/2
        let realY0 = y0 - realH/2
        boxes.push({
          classId: classId,
          probability: score,
          bounding: [realX0, realY0, realW, realH],
        });
      }
    }

    boxes = nms(boxes, 0.3)

    for (let r = 0; r < boxes.size; r += 1) {
      let b = boxes[r]
      boxes[r] = {
        x1: b.x1/IMG_SIZE*inputImageRef.current.width,
        y1: b.y1/IMG_SIZE*inputImageRef.current.height,
        x2: b.x2/IMG_SIZE*inputImageRef.current.width,
        y2: b.y2/IMG_SIZE*inputImageRef.current.height,
        width: b.width/IMG_SIZE*inputImageRef.current.width,
        height: b.height/IMG_SIZE*inputImageRef.current.height,
        area: b.area
      }
    }

    renderBoxes(ctx, boxes, labels, setProcessing); // Draw boxes
  };

  const handleClick = async (i) => {
    await setProcessing(true);
    inputImageRef.current.src = require('./images/full_example_image_'+i+'.jpg')
    outputImageRef.current.src = require('./images/full_example_image_'+i+'.jpg')
    await setImg(true)
  }

  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, canvasRef.width, canvasRef.height);
    inputImageRef.current.onload = () => {
      detectImage(ctx);
    };
  }, [processing]);

  useEffect(() => {
    cv["onRuntimeInitialized"] = () => {
      ort.InferenceSession.create(`${process.env.PUBLIC_URL}/model/${modelName}.onnx`).then(
        (yolov7) => {
          setSession(yolov7);
        }
      );
    };
    
    ort.InferenceSession.create(`${process.env.PUBLIC_URL}/model/${modelName}.onnx`).then(
      (yolov7) => {
        setSession(yolov7);
      }
    ).catch((e) => {
      console.log('Session init error: '+JSON.stringify(e))
    });

    drop.current.addEventListener('dragover', handleDragOver);
    drop.current.addEventListener('drop', handleDrop);
  
    return () => {
      drop.current.removeEventListener('dragover', handleDragOver);
      drop.current.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const formats = ['jpg', 'jpeg', 'png']

  const handleDrop = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    // this is required to convert FileList object to array
    const files = [...e.dataTransfer.files];

    // check if some uploaded file is not in one of the allowed formats
    if (formats && files.some((file) => !formats.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase())))) {
      console.log(`Only following file formats are acceptable: ${formats.join(', ')}`);
      return;
    }

    if (files && files.length) {
      const url = URL.createObjectURL(files[0])
      await setProcessing(true);
      inputImageRef.current.src = url
      outputImageRef.current.src = url
      await setImg(true)
    }
  };

  return (
    <div className="App">
      <NewHeader />
      <Section style={{marginTop: '8rem'}}>
        <TwoHalvesContent ref={drop}>
          <div style={{position: 'relative'}}>
            <MainTitleContent style={{width: 'fit-content', margin: '0 auto'}}>
              <H1 style={{width: 'fit-content'}}>Optimize Field Sales with image recognition</H1>
              <SubTitle style={{width: 'fit-content', marginTop: '24px'}}>Leverage our Automated Image Recognition to analyze position, price, share, competitors, out-of-stocks, and more.</SubTitle>
            </MainTitleContent>
            <MainTitleBgImg src={require('./images/title_bg_illustration.png')}/>
          </div>
          <div style={{width: 'fit-content', margin: '0 auto'}}>
            <div>
              <UploadCta>
                <input
                  type="file"
                  ref={inputImage}
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setProcessing(true);
                    const url = URL.createObjectURL(e.target.files[0]);
                    inputImageRef.current.src = url
                    outputImageRef.current.src = url
                    setImg(true)
                    const ctx = canvasRef.current.getContext("2d");
                    ctx.clearRect(0, 0, canvasRef.width, canvasRef.height);
                    inputImageRef.current.onload = () => {
                      detectImage(ctx);
                    };
                  }}
                />
                <CTAContainer style={{width: 'fit-content', margin: '0 auto'}}>
                  {" "}
                  <a
                    href="#!"
                    onClick={() => {
                      inputImage.current.click();
                    }}
                  >
                    <MenuCTAbutton id="start_trial" style={{width: 'fit-content', margin: '0 auto'}}>
                      Upload an image
                    </MenuCTAbutton>
                  </a>
                </CTAContainer>
                <GiveItATryImg src={require('./images/give_it_a_try.png')}/>
              </UploadCta>
              <ExamplesContainer>
                <div>
                  <P style={{margin: '4px auto 0', fontWeight: 600}}>No image?</P>
                  <P style={{margin: '12px auto 0', fontWeight: 600}}>Try one of these</P>
                </div>
                <ExamplesImgsContainer>
                  <img src={require('./images/example_image_1.png')} onClick={e => handleClick('1')}/>
                  <img src={require('./images/example_image_2.png')} onClick={e => handleClick('2')}/>
                  <img src={require('./images/example_image_3.png')} onClick={e => handleClick('3')}/>
                  <img src={require('./images/example_image_4.png')} onClick={e => handleClick('4')}/>
                </ExamplesImgsContainer>
              </ExamplesContainer>
            </div>
          </div>
        </TwoHalvesContent>
      </Section>
      <Section style={{backgroundColor: '#F7F9FA', display: !img ? 'none' : 'inherit'}}>
        <Content>
          <H2>Results</H2>
          <PredictionContent style={{display: processing ? 'none' : 'grid'}}>
            <div>
              <img id="input" ref={inputImageRef} src="#" alt="" style={{margin: '0', width: '100%'}} />
            </div>
            {img ? (<ResultsArrowContainer>
              <ResultsArrowImg src={require('./images/results_arrow.png')} />
            </ResultsArrowContainer>) : null}
            <div style={{position: 'relative'}}>
              <img id="output" ref={outputImageRef} src="#" alt="" style={{margin: '0', width: '100%'}} />
              <canvas id="canvas" width={IMG_SIZE} height={IMG_SIZE} ref={canvasRef} style={{position: 'absolute', top: 0, left: 0, width: '100%', height: 'calc(100% - 5px)'}} />
              {processing ? (<div id="processingLoader" style={{position: 'absolute', top: 0, left: 0, width: '100%', height: 'calc(100% - 5px)', backgroundColor: 'rgba(255, 255, 255, 0.6)'}}>
                <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                  <CircularProgress />
                </div>
              </div>) : null}
            </div>
          </PredictionContent>
          <div style={{display: processing ? 'block' : 'none'}}>
            <div style={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: 'relative', width: 'fit-content'}}>
              <CircularProgress />
            </div>
          </div>
          <P style={{width: 'fit-content', marginTop: '24px'}}>FieldPro Detect flexibly accommodates diverse client data collection capabilities and distinct retail environments. Our AI technology efficiently captures, labels, and annotates product images across all retail settings in order to reduced Out-of-Stock Instances and do an Accurate Inventory Management leading to better supply chain management.FieldPro Detect flexibly accommodates diverse client data collection capabilities and distinct retail environments. Our AI technology efficiently captures, labels, and annotates product images across all retail settings in order to reduced Out-of-Stock Instances and do an Accurate Inventory Management leading to better supply chain management.</P>
        </Content>
      </Section>
      <Section>
        <Content>
          <H2>Streamline your field operations with our cutting-edge AI solution, FieldPro Detect</H2>
          <FeatureCardODContainer>
            <FeatureCardODTextContent>
              <H2 style={{color: 'white'}}>Object Detection</H2>
              <P style={{color: 'white'}}>With object detection, we can acquire accurate data for analysis.</P>
            </FeatureCardODTextContent>
            <div>
              <img src={require('./images/object_detection.png')} />
            </div>
          </FeatureCardODContainer>
          <FeatureCardKPIContainer>
            <H2 style={{color: 'white'}}>KPIs measurable using FieldPro Detect</H2>
            <FeatureCardKPIsCardsContainer>
              <img src={require('./images/presence_analysis.png')} />
              <img src={require('./images/facings_analysis.png')} />
              <img src={require('./images/out_of_stock_detection.png')} />
              <img src={require('./images/share_of_shelf.png')} />
            </FeatureCardKPIsCardsContainer>
          </FeatureCardKPIContainer>
          <FeatureCardPlanogramContainer style={{gridTemplateColumns: '50% 50%', gridColumnGap: '1em', backgroundColor: 'rgba(99, 159, 170, 0.2)'}}>
            <div>
              <img src={require('./images/planogram_compliance.png')} />
            </div>
            <div>
              <H2>Automated Planogram Compliance Check</H2>
              <P>Monitor in real-time compliance with planograms, ensuring that your products are being displayed in the correct location, in the correct quantity, and with the correct branding.</P>
            </div>
          </FeatureCardPlanogramContainer>
        </Content>
      </Section>
      <Cta />
      <Footer/>

    </div>
  );
};

export default App;
