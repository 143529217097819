/**
 * Render prediction boxes
 * @param {React.MutableRefObject} canvasRef canvas tag reference
 * @param {Array[Object]} boxes boxes array
 * @param {Array} labels labels array
 */
export const renderBoxes = (ctx, boxes, labels, setProcessing) => {
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height); // clean canvas

  // font configs
  const font = "18px sans-serif";
  ctx.font = font;
  ctx.textBaseline = "top";

  boxes.forEach((box) => {
    const {x1, y1, width, height} = box;

    // Draw the bounding box.
    ctx.strokeStyle = "#00FF00";
    ctx.lineWidth = 2;
    ctx.strokeRect(x1, y1, width, height);

    // // Draw the label background.
    // ctx.fillStyle = "#00FF00";
    // const textWidth = ctx.measureText(klass + " - " + score + "%").width;
    // const textHeight = parseInt(font, 10); // base 10
    // ctx.fillRect(x1 - 1, y1 - (textHeight + 2), textWidth + 2, textHeight + 2);

    // // Draw labels
    // ctx.fillStyle = "#000";
    // ctx.fillText(klass + " - " + score + "%", x1 - 1, y1 - (textHeight + 2));
  });
  setProcessing(false);
};
